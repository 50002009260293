<template>
  <div class="container tool-list-container mt-4">
    <div v-if="paginatedTools.length > 0">
      <div align="center" class="w-100  mb-5">
        <div class="tool-card" v-for="tool in paginatedTools" :key="tool.id">
          <div class="tool-card-details">
            <img class="tool-card-image" :src="tool.image" alt="Tool Image" />
            <b-button class="tool-card-button" :href="tool.document" block variant="warning">Download</b-button>
          </div>
          <div style="align-items: start;" class="tool-card-details">
            <!-- $Can('support-edit') -->
            <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.title }}</h3>
            <p style="font-size:1em">{{ tool.description }}</p>
          </div>
        </div>
        <paginate
          style="place-content: center;"
          :page-count="pageCount"
          :click-handler="changePage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
        ></paginate>
      </div>
    </div>

    <div class="w-100" v-else>
      <b-alert variant="info" show>
        <h4 class="alert-heading">
          File Not Found
        </h4>
        <div class="alert-body">
          <span>There are no files matching your request.</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import router from '@/router';
import 'animate.css';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    Paginate,
  },

  setup() {},

  data() {
    return {
      fetchUsers: [],

      filterOn: ['id', 'name', 'surname', 'email', 'role', 'status'],
      tableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'name', sortable: true, class: 'text-center' },
        { key: 'surname', sortable: true, class: 'text-center' },
        { key: 'email', sortable: true, class: 'text-center' },
        { key: 'role', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: false },
      ],
      perPage: 3,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      tools: [],
      loading: false,
    };
  },

  methods: {
    getTools() {
      this.loading = true;

      axiosIns
        .get('tools')
        .then((response) => {
          this.tools = response.data;
          this.totalRows = this.tools.length;
          this.loading = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },

    clickTitle(tool) {
      if (this.$Can('support_edit')) {
        router.push({ name: 'support-edit', params: { id: tool.id } });
      }
    },
  },

  created() {
    this.getTools();
  },

  computed: {
    pageCount() {
      return Math.ceil(this.tools.length / this.perPage);
    },

    paginatedTools() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      return this.tools.slice(startIndex, startIndex + this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.tool-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-card {
  display: flex;
  // justify-content: center;
  width: 70%;
  background-color: white;
  min-width: 500px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  border: solid 1px #0b4666;
}

.tool-card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.tool-card-image {
  width: 240px;
  height: 240px;
  margin-right: 24px;
  border-radius: 8px;
}

.tool-card-details {
  text-align: left;
}

.tool-card-details h3 {
  margin-top: 0;
  margin-bottom: 16px;
  cursor: pointer;
  font-weight: bold;
}

.tool-card-details p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.5;
}

.tool-card-button {
  margin-top: 16px;
  width: 240px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
